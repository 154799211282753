const echarts = require("echarts");
import "./utilities/world.js";
import "./utilities/xinjiang.js";

const __xinjiangMap = {
	renderMap(map, data) {
        let YELLOW_COLOR = "#FFF166";
        let mapWorldChart = null;
        mapWorldChart = echarts.init(document.getElementById("map"));
        let mapData = [{
                name: "托克逊县",
                value: [88.65384, 42.79181]
            },
            {
                name: "温宿县",
                value: [80.23914, 41.27706]
            },
            {
                name: "昭苏县",
                value: [81.13102, 43.15724]
            },
            {
                name: "疏勒县",
                value: [76.04797, 39.40]
            },
            {
                name: "巴州",
                value: [86.26075, 41.34392],
                label: {
                    normal: {
                        textStyle: {
                            fontSize: 15,
                            color: "#FF5454"
                        }
                    }
                }
            },
            {
                name: "石河子",
                value: [85.94, 44.27],
                label: {
                    normal: {
                        textStyle: {
                            fontSize: 15,
                            color: "#FF5454"
                        }
                    }
                }
            }
        ]
        let mapWorldOpts = [{
            tooltip: {
                trigger: 'item',
                formatter: function (e) {
                    if (e.name == "石河子市" || e.name == "巴音郭楞蒙古自治州") {
                        return e.name;
                    }
                },
            },
            geo: {
                map: 'world',
                width: "100%",
                center: [84.9, 41.74633],
                zoom: 5,
                silent:true,
                label: {
                    normal: {
                        // show: true,
                        textStyle: {
                            color: 'rgba(0,0,0,0.4)'
                        }
                    }
                },
                itemStyle: {
                    normal: {
                        borderWidth: 2,
                        areaColor:"none",
                        borderColor: 'none'
                    },
                    emphasis: {
                        areaColor: null,
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        shadowBlur: 20,
                        borderWidth: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
            },
            series: [{
                    silent: true,
                    roam: false,
                    cursor: "default",
                    name: 'world-map',
                    type: 'map',
                    map:'world',
                    geoIndex: 0,
                    itemStyle: {
                        normal: {
                            borderWidth: .5,
                            borderColor: '#01aff4',
                            areaColor: "#0079e4"
                        },
                        emphasis: {
                            borderWidth: .5,
                            borderColor: '#00C0EE',
                            areaColor: "transparent"
                        }
                    }
                },
                {
                    type: 'map',
                    silent: false,
                    roam: false,
                    zoom:1.2,
                    cursor: "default",
                    label: {
                        cursor: "default",
                        normal: {
                            show: false,
                            textStyle: {
                                fontSize: 18,
                                color: "#895139"

                            }
                        },
                        emphasis: {
                            show: false
                        }
                    },
                    itemStyle: {
                        cursor: "default",
                        normal: {
                            borderWidth: 1,
                            borderColor: '#01aff4',
                            areaColor: "#0079e4"
                        },
                        emphasis: {
                            show: false,
                            borderWidth: 1,
                            borderColor: '#01aff4',
                            areaColor: "#0079e4"
                        }
                    },
                    data: [data],
                    map: "新疆"
                },
                {
                    symbolOffset: [0, '50%'],
                    type: 'effectScatter',
                    silent: true,
                    coordinateSystem: 'geo',
                    showEffectOn: 'render',
                    rippleEffect: {
                        period: 5,
                        scale: 4,
                        brushType: 'stroke'
                    },
                    cursor: "default",
                    label: {
                        show: true,
                        color: "#fff",
                        // position: 'top',
                        textShadowOffsetY: 2,
                        fontSize: 14,
                        textShadowOffsetX: 0,
                        textShadowBlur: 2,
                        textShadowColor: '#000',
                        formatter: "{b}",
                        position: [16, -2],
                    },
                    hoverAnimation: true,
                    itemStyle: {
                        normal: {
                            color: '#4DEEFF',
                            shadowBlur: 4,
                            shadowColor: '#333'
                        }
                    },
                    data: mapData
                }
            ]
        }];
        mapWorldChart.setOption(mapWorldOpts[0], true);
        mapWorldChart.on('click', function (ev) {
            if (ev.name == "昌吉回族自治州") {
                window.location.href = "https://www.kczg.org.cn/zt530activity/cityIndex?province=650000&city=659001600";
            }
            if (ev.name == "巴州" || ev.name == "巴音郭楞蒙古自治州") {
                window.location.href = "https://www.kczg.org.cn/zt530activity/cityIndex?province=650000&city=652800";
            }
        });
    },
    init(){
    	__xinjiangMap.renderMap();
    }
}

$(()=>{
	__xinjiangMap.init();
})